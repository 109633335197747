<template>
    <div class="page addressListBox">
        <div class="addressList">
            <AddressItem v-for="item in addressList" :key="item.id" :item="item" v-on:delItem="deleteAddress" v-on:editAddress="editAddress"
                         v-on:editDefault="editDefault"
                         v-on:chooseAddress="chooseAddress"/>
        </div>
        <div class="footer">
            <span @click="goCreateAddress">添加新地址</span>
        </div>
    </div>
</template>

<script>
    import BottomCom from "../components/BottomCom";
    import ConvertDetailItem from "../components/ConvertDetailItem";
    import {delUserAddress, getAddressList, updateUserDefaultAddress} from "../api/data";
    import _ from "lodash";
    import {Toast} from "vant";
    import ProductModal from "../components/ProductModal";
    import AddressItem from "../components/AddressItem";

    export default {
        name: "Address",
        components: {AddressItem, ProductModal, ConvertDetailItem, BottomCom},
        data: function () {
            return {
                productModal: false,
                addressList: []
            }
        },
        async beforeMount() {
            await this.queryAddressList();
        },
        methods: {
            async chooseAddress(address) {
                localStorage.setItem("addressInfo", JSON.stringify(address));
                await this.$router.back();
            },
            async editDefault(addressId) {
                let param = {
                    id: addressId,
                    status: 2,
                    default: 1,
                };
                let res = await updateUserDefaultAddress(param);
                if (_.toInteger(res.code) === 1) {
                    await this.queryAddressList();
                } else {
                    Toast.fail(res.msg);
                }
            },
            async deleteAddress(addressId) {
                let param = {id: addressId};
                let res = await delUserAddress(param);
                if (_.toInteger(res.code) === 1) {
                    await this.queryAddressList();
                } else {
                    Toast.fail(res.msg);
                }
            },
            async queryAddressList() {
                let param = {};
                let res = await getAddressList(param);
                if (_.toInteger(res.code) === 1) {
                    this.addressList = res.data.data;
                } else {
                    Toast.fail(res.msg);
                }
            },
            async editAddress(addressId) {
                await this.$router.push({name: 'CreateAddress', query: {id: _.toString(addressId)}});
            },
            async goCreateAddress() {
                await this.$router.push('/CreateAddress')
            },
        }
    }
</script>

<style scoped>
    .addressListBox {
        display: flex;
        flex-direction: column;
        width: 690px;
        flex: 1;
        justify-content: flex-start;
        align-items: center;
        box-sizing: border-box;
        padding: 24px 24px 144px 24px;
    }


    .addressListBox .addressList {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        flex: 1;
    }

    .addressListBox .footer {
        position: fixed;
        bottom: 0;
        background: #FFFFFF;
        width: 690px;
        height: 120px;
        box-sizing: border-box;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: 0 40px;
    }

    .addressListBox .footer span {
        width: 100%;
        height: 80px;
        border: 1px solid #707070;
        background: linear-gradient(125deg, #3A3D43 0%, #202327 100%);
        border-radius: 40px;
        font-size: 30px;
        font-weight: bold;
        color: #E2BE93;
        display: flex;
        justify-content: center;
        align-items: center;
    }
</style>
