<template>
    <div class="addressItem">
        <div class="top" @click="chooseAddress(item)">
            <div class="left">
                <div class="title">{{item.name + ' ' + item.phone}}</div>
                <div class="desc van-multi-ellipsis--l2">
                    {{item.province_name+item.city_name+item.area_name+item.address}}
                </div>
            </div>
            <div class="right">
                <img src="../assets/icon.png"/>
            </div>
        </div>
        <div class="foot">
            <div class="default" @click="editAddressDefault(item.id)">
                <div class="box"><span :class="item.default === '1' ? 'check':'noCheck'"></span></div>
                设为默认
            </div>
            <div class="edit" @click="editAddressInfo(item.id)">编辑 <img src="../assets/edit.png"/></div>
            <div class="del" @click="delAddress(item.id)">删除 <img src="../assets/delete.png"/></div>
        </div>
    </div>
</template>

<script>
    import {Dialog} from 'vant';

    export default {
        name: "AddressItem",
        props: ["item"],
        methods: {
            async editAddress(addressId) {
                await this.$router.push({name: 'CreateAddress', query: {id: addressId}});
            },
            chooseAddress(address) {
                this.$emit('chooseAddress', address)
            },
            editAddressInfo(addressId) {
                this.$emit('editAddress', addressId)
            },
            editAddressDefault(addressId) {
                this.$emit('editDefault', addressId)
            },
            delAddress(addressId) {
                Dialog.confirm({
                    title: '提示',
                    message: '您确定要删除此地址',
                }).then(() => {
                    this.$emit('delItem', addressId)
                }).catch(() => {
                    // on cancel
                });
            }
        }
    }
</script>

<style scoped>
    .addressItem {
        width: 642px;
        border: 2px solid #D4CECB;
        background: linear-gradient(131deg, #3A3D43 0%, #222529 100%);
        border-radius: 12px;
        box-sizing: border-box;
        padding: 35px;
        margin-bottom: 20px;
    }

    .addressItem .top {
        width: 100%;
        flex: 1;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: 25px 0;
    }

    .addressItem .top .left {
        width: 100%;
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
    }

    .addressItem .top .left .title {
        font-size: 30px;
        font-weight: 500;
        color: #E8C8AF;
    }

    .addressItem .top .left .desc {
        margin-top: 15px;
        font-size: 26px;
        font-weight: 400;
        line-height: 38px;
        color: #B19485;
    }

    .addressItem .top .right {
        width: 8px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        margin-left: 20px;
    }

    .addressItem .top .right img {
        width: 8px;
        height: 18px;
    }

    .addressItem .foot {
        width: 100%;
        padding-top: 24px;
        border-top: 1px solid #B19485;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }

    .addressItem .foot .default {
        flex: 1;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        font-size: 24px;
        font-weight: 500;
        color: #E8C8AF;
    }

    .addressItem .foot .default .box {
        width: 24px;
        height: 24px;
        border: 2px solid #E8C8AF;
        border-radius: 50%;
        margin-right: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .addressItem .foot .default .box .check {
        width: 12px;
        height: 12px;
        border-radius: 50%;
        background: #E8C8AF;
    }

    .addressItem .foot .edit {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        font-size: 24px;
        font-weight: 500;
        color: #E8C8AF;
        margin-right: 50px;
    }

    .addressItem .foot .edit img {
        margin-left: 10px;
        width: 26px;
        height: 26px;
    }

    .addressItem .foot .del {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        font-size: 24px;
        font-weight: 500;
        color: #E8C8AF;
    }

    .addressItem .foot .del img {
        margin-left: 10px;
        width: 26px;
        height: 26px;
    }
</style>
